import { TableRowData } from '../components/MultiViewTable/MultiViewTable';

/**
 * Formats metrics names and values nicely.
 *
 * Replace underscores with spaces, capitalizes words, truncates long decimals, and sorts metrics by name.
 *
 * @param {TableRowData[]} tableRows - The raw metric data in TableRowData objects.
 * @returns Formatted TableRowData[].
 */
export const formatMetricNamesAndValues = (
	tableRows: TableRowData[],
): TableRowData[] => {
	return tableRows
		.map((row) => {
			// Format metric name
			const formattedName = row.name
				.replace(/_/g, ' ')
				.replace(/\b\w/g, (letter) => letter.toUpperCase());

			let formattedValue = row.value;
			// Round 0.0 to 0
			if (formattedValue.endsWith('.0')) {
				formattedValue = formattedValue.slice(0, -2);
			}

			// Format metric values for 'miles_driven'
			if (row.name === 'miles_driven') {
				// Round to tenths place if it's a decimal
				if (formattedValue.includes('.')) {
					formattedValue = parseFloat(row.value).toFixed(1);
				}
				// Add "mi" suffix after value
				formattedValue += ' mi';
			}

			return {
				...row,
				name: formattedName,
				value: formattedValue,
			};
		})
		.sort((a, b) => a.name.localeCompare(b.name));
};
