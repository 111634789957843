import { useEffect, useMemo, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { styled } from 'styled-components';
import PageHeader from '../../components/PageHeader/PageHeader';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { Tractor, useTractors } from '../../contexts/TractorContext';
import Map, { MapVehicle } from '../../components/Map/Map';
import { LatLng, LatLngExpression } from 'leaflet';
import TldStatus from '../../components/TldStatus/TldStatus';
import MultiViewTable, {
	View,
} from '../../components/MultiViewTable/MultiViewTable';
import {
	getLifetimeMetrics,
	getThisWeekMetrics,
	getTodayMetrics,
} from './performanceIndicatorViews';
import { atlMapCenter } from '../../utils/constants';
import GreyedOutMap from '../../components/GreyedOutMap/GreyedOutMap';

const Dashboard = () => {
	const navigate = useNavigate();
	const { tractors } = useTractors();
	const [isSmallScreen, setIsSmallScreen] = useState(
		window.innerWidth <= 600,
	);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 600);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	/**
	 * Takes in the name -> tractor mapping from TractorContext.tsx and transforms the data into the format Map.tsx expects.
	 *
	 * @param tractors - The tractors state from TractorContext.tsx.
	 * @returns An array of MapVehicles for the Map to render.
	 */
	const constructMapVehicles: (
		tractors: Map<string, Tractor>,
	) => MapVehicle[] = (tractors: Map<string, Tractor>) => {
		const mapVehicles: MapVehicle[] = [];
		for (const [tractorName, tractor] of tractors.entries()) {
			if (tractor.statuses?.online && tractor.statuses.position) {
				const newMapVehicle: MapVehicle = {
					label: tractor.displayName,
					position: new LatLng(
						tractor.statuses.position.lat,
						tractor.statuses.position.lon,
					),
					action: () => navigate(`/vehicles/${tractor.displayName}`),
				};
				mapVehicles.push(newMapVehicle);
			}
		}
		return mapVehicles;
	};

	/**
	 * Takes in the name -> tractor mapping from TractorContext.tsx and returns the number of tractors online.
	 *
	 * @param tractors - The tractors state from TractorContext.tsx.
	 * @returns The number of tractors online.
	 */
	const calculateNumTractorsOnline: (
		tractors: Map<string, Tractor>,
	) => number = (tractors) => {
		return Array.from(tractors.values()).filter(
			(tractor) => tractor.statuses.online,
		).length;
	};

	// Whenever we get new tractor data, recalculate the number online, number offline, and number total.
	const numTractorsOnline = useMemo(
		() => calculateNumTractorsOnline(tractors),
		[tractors],
	);
	const numTractorsTotal = useMemo(() => tractors.size, [tractors]);
	const numTractorsOffline = useMemo(
		() => numTractorsTotal - numTractorsOnline,
		[numTractorsTotal, numTractorsOnline],
	);

	// Center the map on ATL airport
	const mapCenter: LatLngExpression = atlMapCenter;

	// Reconstruct map whenever tractor data changes
	const mapVehicles = useMemo(
		() => constructMapVehicles(tractors),
		[tractors],
	);

	const performanceIndicatorViews: View[] = [
		{
			name: 'Today',
			dataSource: getTodayMetrics,
			params: [],
		},
		{ name: 'This Week', dataSource: getThisWeekMetrics, params: [] },
		{ name: 'All Time', dataSource: getLifetimeMetrics, params: [] },
	];

	return (
		<FullPageContainer>
			<Navbar />
			<Right>
				<PageHeader>Dashboard</PageHeader>
				<Content>
					<TileContainer>
						<Flex>
							<Statuses>
								<StatusRow>
									<StatusName>Total Vehicles</StatusName>
									<StatusValue>
										{numTractorsTotal}
									</StatusValue>
								</StatusRow>
								<StatusRow>
									<StatusName>Online</StatusName>
									<StatusValue>
										{numTractorsOnline}
									</StatusValue>
								</StatusRow>
								<StatusRow>
									<StatusName>Offline</StatusName>
									<StatusValue>
										{numTractorsOffline}
									</StatusValue>
								</StatusRow>
								<Tractors>
									{Array.from(tractors.entries()).map(
										([name, tractor]) => (
											<TldStatus
												key={tractor.displayName}
												label={tractor.displayName}
												status={
													tractor.statuses.online
														? 'online'
														: 'offline'
												}
												action={() =>
													navigate(
														`/vehicles/${tractor.displayName}`,
													)
												}
											/>
										),
									)}
								</Tractors>
							</Statuses>
							{numTractorsOnline > 0 ? (
								<Map
									center={atlMapCenter}
									vehicles={mapVehicles}
									height={isSmallScreen ? 300 : undefined}
								/>
							) : (
								<GreyedOutMap
									center={atlMapCenter}
									height={isSmallScreen ? 300 : undefined}
								>
									All vehicles are currently offline
								</GreyedOutMap>
							)}
						</Flex>

						<MultiViewTable
							title="Performance Indicators"
							views={performanceIndicatorViews}
						/>
					</TileContainer>
				</Content>
				<Footer />
			</Right>
		</FullPageContainer>
	);
};

export const FullPageContainer = styled.div`
	height: 100%;
	min-height: 100vh;
	width: 100%;
	display: flex;

	@media (max-width: 1032px) {
		flex-direction: column;
	}
`;

export const Right = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.background};
	height: 100%;
	min-height: 100vh;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	padding: 40px;
	height: 100%;
	gap: 40px;
`;

export const TileContainer = styled.div`
	width: 100%;
	height: min-content;
	border-radius: 10px;
	border: 1px solid ${(props) => props.theme.colors.lightGrey};
	overflow: hidden;
`;

export const Flex = styled.div`
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
	height: 100%;
	width: 100%;

	@media (max-width: 600px) {
		flex-direction: column;
	}
`;

export const Statuses = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 10px;
	width: 30%;
	max-width: 300px;
	border-right: 1px solid ${(props) => props.theme.colors.lightGrey};

	@media (max-width: 600px) {
		max-width: 100%;
		width: 100%;
		flex-direction: column;
		border: none;
	}
`;

export const StatusRow = styled.div`
	padding: 20px 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

export const StatusName = styled.span`
	font-size: 18px;
	margin-right: 20px;
`;

export const StatusValue = styled.span`
	font-size: 20px;
	font-weight: ${(props) => props.theme.fontWeight.medium};
`;

const Tractors = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	padding: 20px 0;
`;

export default Dashboard;
