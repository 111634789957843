import { LatLngExpression } from 'leaflet';
import React, { ReactNode, useEffect } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import './GreyedOutMap.css';

interface GreyedOutMapProps {
	center: LatLngExpression;
	children?: ReactNode;
	height?: number;
}

const GreyedOutMap: React.FC<GreyedOutMapProps> = ({
	center,
	children,
	height,
}: GreyedOutMapProps) => {
	// Effect to handle resizing the map when it's parent container size changes
	const MapEffect = () => {
		const map = useMap();
		useEffect(() => {
			const resizeObserver = new ResizeObserver(() => {
				map.invalidateSize();
			});
			resizeObserver.observe(document.getElementById('map-container')!);
			return () => resizeObserver.disconnect();
		}, [map]);

		return null;
	};
	return (
		<div
			id="map-container"
			style={{
				width: '100%',
				position: 'relative',
				...(height && { height: `${height}px` }),
			}}
		>
			<div className="transparent-grey">{children}</div>
			<MapContainer
				center={center}
				zoom={15}
				style={{ width: '100%', height: '100%' }}
				attributionControl={false}
			>
				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

				<MapEffect />
			</MapContainer>
		</div>
	);
};

export default GreyedOutMap;
