import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface RequireAuthProps {
	children: React.ReactNode;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
	const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

	useEffect(() => {
		if (!isLoading && !isAuthenticated) {
			loginWithRedirect();
			return;
		}
	}, [isAuthenticated, isLoading]);

	return isAuthenticated ? <>{children}</> : null;
};

export default RequireAuth;
