import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from 'styled-components';
import { theme } from './assets/theme';
import 'normalize.css';
import 'leaflet/dist/leaflet.css';
import { TractorProvider } from './contexts/TractorContext';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(
	<React.StrictMode>
		<Auth0Provider
			domain="aerovect.us.auth0.com"
			clientId="O0XeQ5cdGgreBtITw5gLq0klFD74m6mN"
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: 'https://fleet.aerovect.com/',
				scope: 'openid profile email read:aerovect-vehicles read:delta-vehicles create:delta-ride-request create:aerovect-ride-request',
			}}
		>
			<TractorProvider>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</TractorProvider>
		</Auth0Provider>
	</React.StrictMode>,
);
