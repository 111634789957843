import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TldSvg } from '../../assets/tld.svg';
import { hexToRgb } from '../../utils/hexToRgb';

type Status = 'online' | 'offline' | 'incident';

interface TldStatusProps {
	label: string;
	status: Status;
	action: () => void;
}

const TldStatus: React.FC<TldStatusProps> = ({
	label,
	status,
	action,
}: TldStatusProps) => {
	return (
		<Container>
			<Label onClick={action}>{label}</Label>
			<TldContainer status={status}>
				<TldSvg style={{ width: '75px', height: 'auto' }} />
			</TldContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
`;

const Label = styled.span`
	color: ${(props) => props.theme.colors.blue};
	font-size: 14px;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`;

const TldContainer = styled.div<{ status: Status }>`
	padding: 10px;
	border-radius: 10px;
	background-color: ${(props) => {
		switch (props.status) {
			case 'online':
				return `rgba(${hexToRgb(props.theme.colors.green)}, 0.5)`;
			case 'offline':
				return props.theme.colors.lightGrey;
			case 'incident':
				return `rgba(${hexToRgb(props.theme.colors.danger)}, 0.5)`;
			default:
				return 'transparent';
		}
	}};
`;

export default TldStatus;
