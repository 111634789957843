import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { formatVehicleName } from '../utils/tractorNameRemapping';

export type Tractor = {
	displayName: string;
	statuses: {
		online: boolean;
		autonomy_enabled: boolean;
		destination: string | null;
		requestedRide: string | null;
		position: {
			lat: number;
			lon: number;
		} | null;
	};
	metrics: {
		[key: string]: any;
	};
};

interface TractorContextType {
	tractors: Map<string, Tractor>;
	setTractors: React.Dispatch<React.SetStateAction<Map<string, Tractor>>>;
}

interface TractorProviderProps {
	children: React.ReactNode;
}

const TractorContext = createContext<TractorContextType | undefined>(undefined);

export const TractorProvider: React.FC<TractorProviderProps> = ({
	children,
}) => {
	const [tractors, setTractors] = useState<Map<string, Tractor>>(new Map());
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();

	// Ensure backend server url is set
	if (!process.env.REACT_APP_BACKEND_SERVER_URL) {
		console.error('BACKEND URL NOT SET!');
	}

	/**
	 * Converts destination names from uppercase with spaces to Title Case.
	 * This function uses a lookup to convert specific known names, falling back to a generic conversion if the name is not found.
	 *
	 * @param destination - The original destination name in uppercase.
	 * @returns The converted destination name in Title Case.
	 */
	const convertDestinationName = (
		destination: string | null,
	): string | null => {
		const lookup: { [key: string]: string } = {
			'CARGO YARD': 'Cargo Yard',
			'T-POINT': 'T-Point',
		};

		if (destination && lookup[destination]) {
			return lookup[destination];
		}

		// Fallback for any other destinations not explicitly listed in the lookup
		return destination
			? destination
					.split(' ')
					.map(
						(word) =>
							word.charAt(0).toUpperCase() +
							word.slice(1).toLowerCase(),
					)
					.join(' ')
			: null;
	};

	/**
	 * Extracts tractors from API response or dummy data and converts them into a Map.
	 * This function handles the conversion of property names from snake_case to camelCase
	 * and ensures that all tractors have the necessary default statuses and metrics.
	 *
	 * @param data - The raw data object received from the API or dummy data.
	 * @returns A Map of tractors keyed by their identifiers.
	 */
	const extractTractorsFromRawData = (data: {
		[key: string]: any;
	}): Map<string, Tractor> => {
		const tractorsMap = new Map<string, Tractor>();
		for (const [key, value] of Object.entries(data)) {
			// If the tractor has no statuses, default it to offline and null
			if (!value?.statuses) {
				value['statuses'] = {
					online: false,
					autonomy_enabled: false,
					destination: null,
					requestedRide: null,
					position: null,
				};
			}

			// Convert destination name using the helper function
			const convertedDestination = convertDestinationName(
				value?.statuses.destination,
			);

			// Put the JSON object into a Tractor object
			let tractor: Tractor = {
				displayName: formatVehicleName(key),
				statuses: {
					online: value?.statuses.online,
					autonomy_enabled: value?.statuses.autonomy_enabled ?? null,
					destination: convertedDestination,
					requestedRide: value?.statuses.requested_ride ?? null,
					position: value?.statuses.position ?? null,
				},
				metrics: value.metrics ?? {},
			};
			// Put the tractor in the tractors map
			tractorsMap.set(key, tractor);
		}
		return tractorsMap;
	};

	/**
	 * Fetches tractor data from the API or dummy data based on the parameters.
	 * Optionally includes an authorization token for authenticated requests.
	 *
	 * @param useDummyData - Whether to use dummy data instead of fetching from the API.
	 * @param authToken - Optional bearer token for authenticated requests.
	 * @returns The raw data from the API or dummy data.
	 */
	const fetchTractorData = async (
		useDummyData: boolean = false,
		authToken: string | null = null,
	): Promise<any> => {
		if (useDummyData) {
			try {
				const data = await import('./dummy-data.json');
				return data;
			} catch (error) {
				console.error(`Failed to load dummy data: ${error}`);
			}
		}

		try {
			const headers = authToken
				? { Authorization: `Bearer ${authToken}` }
				: {};
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_SERVER_URL}/tractors`,
				{ headers },
			);
			return response.data.data;
		} catch (error) {
			console.error(`Failed to fetch: ${error}`);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = await getAccessTokenSilently();
				const rawData = await fetchTractorData(false, token);
				const tractorsMap = extractTractorsFromRawData(rawData);
				setTractors(tractorsMap);
			} catch (error) {
				console.error(
					`Error fetching or processing tractor data: ${error}`,
				);
			}
		};

		// Fetch tractor data once the user authenticates, then every 10 seconds after that
		if (isAuthenticated) {
			fetchData();

			const interval = setInterval(fetchData, 10000);

			// Stop fetching once the app is closed
			return () => clearInterval(interval);
		}
	}, [isAuthenticated]);

	return (
		<TractorContext.Provider value={{ tractors, setTractors }}>
			{children}
		</TractorContext.Provider>
	);
};

export const useTractors = () => {
	const context = useContext(TractorContext);
	if (context === undefined) {
		throw new Error('useTractors must be used within a TractorProvider');
	}
	return context;
};
