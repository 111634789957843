/**
 * Behaves like a defaultdict in Python.
 *
 * @param defaultValue - The default value that the defaultdict should return if they key does not exist.
 * @returns The value at that key, or ""
 */
function defaultdict<T>(defaultValue: T): Record<string, T> {
	return new Proxy({} as Record<string, T>, {
		get: (target, prop) =>
			typeof prop === 'string' && prop in target
				? target[prop]
				: defaultValue,
	});
}

/**
 * Returns the "display name" of the passed in name. Returns "" if the name is not recognized.
 *
 * @param name - The name of the vehicle.
 * @returns The display name of the vehicle.
 */
export const formatVehicleName = (name: string): string => {
	return vehicleNameRemapping[name] || name;
};

const vehicleNameRemapping = defaultdict('');

// Delta Tractors
vehicleNameRemapping['av6'] = 'AV-DL-01';
vehicleNameRemapping['av7'] = 'AV-DL-02';
vehicleNameRemapping['av8'] = 'AV-DL-03';
vehicleNameRemapping['av9'] = 'AV-DL-04';
vehicleNameRemapping['av10'] = 'AV-DL-05';

// AeroVect Vehicles
vehicleNameRemapping['subaru'] = 'Subaru';
vehicleNameRemapping['av4'] = 'AV4';
