import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { LatLngExpression } from 'leaflet';
import './Map.css';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.fullscreen';
import myLocationIcon from '../../assets/my-location.svg';

export type MapVehicle = {
	position: LatLngExpression;
	label: string;
	action: () => void;
};

interface MapProps {
	center: LatLngExpression;
	vehicles: MapVehicle[];
	isLastKnownPosition?: boolean;
	shouldDisplayRecenterOnTractorButton?: boolean;
	height?: number;
}

const Map: React.FC<MapProps> = ({
	center,
	vehicles,
	isLastKnownPosition,
	shouldDisplayRecenterOnTractorButton,
	height,
}: MapProps) => {
	// Effect to handle resizing the map when it's parent container size changes
	const MapEffect = () => {
		const map = useMap();
		useEffect(() => {
			const resizeObserver = new ResizeObserver(() => {
				map.invalidateSize();
			});
			resizeObserver.observe(document.getElementById('map-container')!);
			return () => resizeObserver.disconnect();
		}, [map]);

		return null;
	};

	// Control to recenter the map on the first MapVehicle in the vehicles array
	const RecenterControl = ({
		position,
	}: {
		position: L.LatLngExpression;
	}) => {
		const map = useMap();
		useEffect(() => {
			const controlDiv = L.DomUtil.create(
				'div',
				'leaflet-bar leaflet-control leaflet-control-custom recenter-control',
			);

			controlDiv.style.backgroundImage = `url(${myLocationIcon})`;
			controlDiv.title = 'Recenter on tractor';

			controlDiv.onclick = function () {
				map.flyTo(position, 15);
			};

			L.DomEvent.disableClickPropagation(controlDiv);
			const leafletTopRightContainer = map
				.getContainer()
				.querySelector('.leaflet-top.leaflet-right');
			if (leafletTopRightContainer) {
				leafletTopRightContainer.appendChild(controlDiv);
			}

			return () => {
				if (leafletTopRightContainer) {
					leafletTopRightContainer.removeChild(controlDiv);
				}
			};
		}, [map, position]);

		return null;
	};

	return (
		<div
			id="map-container"
			style={{ width: '100%', ...(height && { height: `${height}px` }) }}
		>
			<MapContainer
				center={center}
				zoom={15}
				style={{ width: '100%', height: '100%' }}
				attributionControl={false}
			>
				{isLastKnownPosition && (
					<div className="last-known-position">
						Showing last known position
					</div>
				)}
				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
				{vehicles.map((vehicle, index) => {
					const customIcon = L.divIcon({
						className: 'custom-icon',
						html: `	<div class="custom-marker">
									<span class="marker-label">${vehicle.label}</span>
									<div class="marker-circle"></div>
								</div>`,
						iconSize: [50, 70],
						iconAnchor: [25, 35],
					});

					return (
						<Marker
							key={index}
							position={vehicle.position}
							icon={customIcon}
							eventHandlers={{
								click: vehicle.action,
							}}
						/>
					);
				})}
				<FullscreenControl
					position="topright"
					forcePseudoFullscreen
					forceSeparateButton
				/>
				<MapEffect />
				{shouldDisplayRecenterOnTractorButton && (
					<RecenterControl position={vehicles[0].position} />
				)}
			</MapContainer>
		</div>
	);
};

export default Map;
