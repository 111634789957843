import { TableRowData } from '../../components/MultiViewTable/MultiViewTable';
import axios from 'axios';
import { formatMetricNamesAndValues } from '../../utils/formatMetricNamesAndValues';
import { transformRawDataIntoTableRows } from '../../utils/transformRawDataIntoTableRows';

export const getTodayMetrics = async (
	auth: string,
): Promise<TableRowData[]> => {
	const timestampInSeconds = Math.floor(Date.now() / 1000);
	const response = await axios.get(
		`${process.env.REACT_APP_BACKEND_SERVER_URL}/tractors/metrics/day?timestamp=${timestampInSeconds}`,
		{ headers: { Authorization: `Bearer ${auth}` } },
	);

	const data = response.data.data;
	const tableRows = transformRawDataIntoTableRows(data);
	const formattedData = formatMetricNamesAndValues(tableRows);

	return formattedData;
};

export const getThisWeekMetrics = async (
	auth: string,
): Promise<TableRowData[]> => {
	const timestampInSeconds = Math.floor(Date.now() / 1000);
	const response = await axios.get(
		`${process.env.REACT_APP_BACKEND_SERVER_URL}/tractors/metrics/week?timestamp=${timestampInSeconds}`,
		{ headers: { Authorization: `Bearer ${auth}` } },
	);

	const data = response.data.data;
	const tableRows = transformRawDataIntoTableRows(data);
	const formattedData = formatMetricNamesAndValues(tableRows);

	return formattedData;
};

export const getLifetimeMetrics = async (
	auth: string,
): Promise<TableRowData[]> => {
	const response = await axios.get(
		`${process.env.REACT_APP_BACKEND_SERVER_URL}/tractors`,
		{ headers: { Authorization: `Bearer ${auth}` } },
	);

	const data = response.data.data;
	const tableRows = transformRawDataIntoTableRows(data);
	const formattedData = formatMetricNamesAndValues(tableRows);

	return formattedData;
};
