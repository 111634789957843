import React from 'react';
import { styled } from 'styled-components';

interface PageHeaderProps {
	children: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ children }) => {
	return <Container>{children}</Container>;
};

const Container = styled.div`
	font-size: 40px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	padding: 30px 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: ${(props) => props.theme.colors.paper};
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

export default PageHeader;
