import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dashboard, DriveEta, Logout } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AerovectLogoWhite } from '../../assets/aerovect-logo-white.svg';
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { logout, user } = useAuth0();
	const [isMediumScreen, setIsMediumScreen] = useState(
		window.innerWidth <= 1032,
	);
	const [isSmallScreen, setIsSmallScreen] = useState(
		window.innerWidth <= 600,
	);

	useEffect(() => {
		const handleResize = () => {
			setIsMediumScreen(window.innerWidth <= 1032);
			setIsSmallScreen(window.innerWidth <= 600);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<NavbarContainer>
			<Header>
				{isSmallScreen ? (
					<AerovectLogoSmallA
						src={`${process.env.PUBLIC_URL}/aerovect-logo-small-a.svg`}
						alt="Aerovect Logo"
					/>
				) : (
					<AerovectLogoWhite />
				)}{' '}
			</Header>
			<Links>
				<Link
					isActive={
						location.pathname.includes('/dashboard') ||
						location.pathname === '/'
					}
					onClick={() => navigate('/dashboard')}
				>
					<Dashboard fontSize="large" />
					{!isMediumScreen && 'Dashboard'}
				</Link>
				<Link
					isActive={location.pathname.includes('/vehicles')}
					onClick={() => navigate('/vehicles')}
				>
					<DriveEta fontSize="large" />
					{!isMediumScreen && 'Vehicles'}
				</Link>
			</Links>
			<UserSection>
				<LogoutButton onClick={() => logout()}>
					<Logout fontSize="large" />
					{!isMediumScreen && 'Log out'}
				</LogoutButton>
				<ProfileButton>
					<ProfileIcon src={user?.picture} />
					{!isMediumScreen && <EmailSpan>{user?.email}</EmailSpan>}
				</ProfileButton>
			</UserSection>
		</NavbarContainer>
	);
};

const NavbarContainer = styled.div`
	width: 300px;
	height: 100vh;
	position: sticky;
	top: 0;
	background-color: ${(props) => props.theme.colors.navyBlue};
	padding: 40px;
	color: ${(props) => props.theme.colors.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	z-index: 5001;

	@media (max-width: 1032px) {
		flex-direction: row;
		height: 50px;
		width: 100%;
		padding: 40px 20px;
		gap: 10px;
	}
`;

const AerovectLogoSmallA = styled.img`
	height: 100%;
	width: 40px;
	margin-top: 12px;
`;

const Header = styled.h1`
	margin: 0;
	padding: 25px 0;
	font-size: 36px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
`;

const Links = styled.ul`
	margin: 0;
	padding: 0;
	list-style-type: none;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media (max-width: 1032px) {
		flex-direction: row;
		justify-content: center;
		gap: 10px;
	}
`;

const Link = styled.li<{ isActive: boolean }>`
	font-size: 24px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	gap: 20px;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	background-color: ${(props) =>
		props.isActive ? 'rgba(255, 255, 255, 0.1)' : 'none'};
	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}

	@media (max-width: 1032px) {
		width: min-content;
		padding: 5px 10px;
	}
`;

const UserSection = styled.div`
	margin-top: auto;
	width: 100%;
	border-top: 2px solid white;
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media (max-width: 1032px) {
		border: none;
		padding-top: 0;
		flex-direction: row;
		margin-top: 0;
		width: min-content;
	}
`;

const UserButton = styled.button`
	font-size: 16px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 20px;
	padding: 10px;
	border-radius: 5px;
	align-items: center;
	cursor: pointer;
	background-color: transparent;
	color: ${(props) => props.theme.colors.white};
	border: none;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}

	@media (max-width: 1032px) {
		padding: 5px 10px;
	}
`;

const LogoutButton = styled(UserButton)`
	font-size: 20px;
`;

const ProfileButton = styled(UserButton)`
	font-size: 12px;
	gap: 20px;
	cursor: auto;

	&:hover {
		background-color: transparent;
	}
`;

const ProfileIcon = styled.img`
	font-size: 20px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
`;

const EmailSpan = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	max-width: 150px; // Adjust the max-width as needed
`;

export default Navbar;
