import React, { useEffect, useMemo, useState } from 'react';
// TODO extract these from Dashboard
import {
	Content,
	Flex,
	FullPageContainer,
	Right,
	Statuses,
	TileContainer,
} from '../Dashboard/Dashboard';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { styled } from 'styled-components';
import { ChevronRight, AltRoute } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { GreenCircle, RedCircle } from '../Vehicles/Vehicles';
import Map, { MapVehicle } from '../../components/Map/Map';
import { ReactComponent as TldSvg } from '../../assets/tld.svg';
import { useTractors } from '../../contexts/TractorContext';
import { LatLng, LatLngExpression } from 'leaflet';
import MultiViewTable, {
	View,
} from '../../components/MultiViewTable/MultiViewTable';
import {
	getLifetimeMetrics,
	getThisWeekMetrics,
	getTodayMetrics,
} from './metricViews';
import { atlMapCenter } from '../../utils/constants';
import GreyedOutMap from '../../components/GreyedOutMap/GreyedOutMap';

const VehicleDetail = () => {
	const navigate = useNavigate();
	const { tractors } = useTractors();
	const [isSmallScreen, setIsSmallScreen] = useState(
		window.innerWidth <= 600,
	);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 600);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// This is the display name
	const { vehicleName } = useParams();

	// Find the tractor object with the matching displayName
	const tractor = useMemo(() => {
		for (let value of tractors.values()) {
			if (value.displayName === vehicleName) {
				return value;
			}
		}
		return null; // Return null if no matching tractor is found
	}, [tractors, vehicleName]);

	// This is the actual name, or ID. av4 for example
	const tractorID = useMemo(() => {
		for (let [id, value] of tractors.entries()) {
			if (value.displayName === vehicleName) {
				return id;
			}
		}
		return null; // Return null if no matching tractor is found
	}, [tractors, vehicleName]);

	const tractorPosition: LatLngExpression = useMemo(() => {
		// If there's no tractor, return a default position
		// This won't matter since we won't render the map in this case anyway.
		if (!tractor || !tractor.statuses.position) {
			return new LatLng(0, 0);
		}
		return new LatLng(
			tractor.statuses.position.lat,
			tractor.statuses.position.lon,
		);
	}, [tractor]);

	const mapVehicle: MapVehicle[] = useMemo(() => {
		// If there's no tractor, return an empty array
		if (!tractor) {
			return [];
		}
		return [
			{
				position: tractorPosition,
				label: tractor.displayName,
				action: () => {},
			},
		];
	}, [tractor, tractorPosition]);

	const metricViews: View[] = [
		{
			name: 'Today',
			dataSource: getTodayMetrics,
			params: [tractorID],
		},
		{
			name: 'This Week',
			dataSource: getThisWeekMetrics,
			params: [tractorID],
		},
		{
			name: 'All Time',
			dataSource: getLifetimeMetrics,
			params: [tractorID],
		},
	];

	return (
		<FullPageContainer>
			<Navbar />
			<Right>
				{tractor && (
					<>
						<PageHeaderContainer>
							<Breadcrumb onClick={() => navigate('/vehicles')}>
								Vehicles
							</Breadcrumb>
							<Chevron />
							<span
								style={{
									paddingRight: 10,
									textAlign: 'center',
								}}
							>
								{vehicleName}
							</span>
							{tractor.statuses.online ? (
								<GreenCircle />
							) : (
								<RedCircle />
							)}
						</PageHeaderContainer>
						<Content>
							<TileContainer>
								<Flex style={{ borderBottom: 'none' }}>
									<Statuses style={{ minWidth: 250 }}>
										<StatusHeader>
											{tractor.statuses.online ? (
												<GreenCircle />
											) : (
												<RedCircle />
											)}
											{vehicleName}
										</StatusHeader>
										<StatusRow>
											<StatusName>Status</StatusName>
											<StatusValue>
												{tractor.statuses.online
													? 'Online'
													: 'Offline'}
											</StatusValue>
										</StatusRow>
										{tractor.statuses.online && (
											<>
												<StatusRow>
													<StatusName>
														Autonomy Mode
													</StatusName>
													<StatusValue>
														{tractor.statuses
															.autonomy_enabled
															? 'Enabled'
															: 'Disabled'}
													</StatusValue>
												</StatusRow>
												<StatusRow>
													<StatusName>
														Destination
													</StatusName>
													<StatusValue>
														{tractor.statuses
															.destination ||
															'None'}
													</StatusValue>
												</StatusRow>
												{/* <StatusRow>
													<StatusName>
														Requested Ride
													</StatusName>
													<StatusValue>
														{tractor.statuses
															.requestedRide ||
															'None'}
													</StatusValue>
												</StatusRow> */}
											</>
										)}

										<TldSvgContainer>
											<BigTldSvg />
										</TldSvgContainer>
									</Statuses>
									{tractor.statuses.position &&
									tractor.statuses.position.lat !== 0 &&
									tractor.statuses.position.lon !== 0 ? (
										<Map
											center={tractorPosition}
											vehicles={mapVehicle}
											isLastKnownPosition={
												!tractor.statuses.online
											}
											shouldDisplayRecenterOnTractorButton
											height={
												isSmallScreen ? 300 : undefined
											}
										/>
									) : (
										<GreyedOutMap
											center={atlMapCenter}
											height={
												isSmallScreen ? 300 : undefined
											}
										>
											Could not find last known position
										</GreyedOutMap>
									)}
								</Flex>
							</TileContainer>

							<FlexWithGap>
								<TileContainer>
									<MultiViewTable
										title="Metrics"
										views={metricViews}
									/>
								</TileContainer>
								{/* <TileContainer>
									<TileHeader>Actions</TileHeader>
									<ActionRow>
										<AltRoute fontSize="medium" />
										<span>Request a ride</span>
									</ActionRow>
								</TileContainer> */}
							</FlexWithGap>
						</Content>
					</>
				)}
				<Footer />
			</Right>
		</FullPageContainer>
	);
};

const PageHeaderContainer = styled.div`
	font-size: 40px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	padding: 20px 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: ${(props) => props.theme.colors.paper};
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
	gap: 10px;

	@media (max-width: 600px) {
		font-size: 32px;
		gap: 5px;
	}
`;

const Breadcrumb = styled.button`
	background: none;
	border: none;
	font-size: 20px;
	color: ${(props) => props.theme.colors.grey};
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`;

const Chevron = styled(ChevronRight)`
	color: ${(props) => props.theme.colors.grey};
`;

const StatusHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
	font-size: 24px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
	padding: 20px;
`;

const StatusRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
	padding: 20px 10px;

	&:last-child {
		border-bottom: none;
	}
`;

const StatusName = styled.div`
	font-size: 16px;
	font-weight: ${(props) => props.theme.fontWeight.medium};
	margin-right: 20px;
`;

const StatusValue = styled.div`
	font-size: 16px;
	font-weight: ${(props) => props.theme.fontWeight.regular};
`;

const TldSvgContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
`;

const BigTldSvg = styled(TldSvg)`
	max-width: 100px;
	width: 100%;
	height: auto; /* Keeps the aspect ratio intact */
`;

const FlexWithGap = styled(Flex)`
	gap: 40px;
	border-bottom: none;
`;

const TileHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px;
	font-size: 20px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const ActionRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	align-items: center;
	color: ${(props) => props.theme.colors.blue};
	padding: 20px;
	font-size: 18px;
	font-weight: ${(props) => props.theme.colors.semibold};

	&:hover {
		cursor: pointer;
		color: ${(props) => props.theme.colors.blueHover};
	}
`;

export default VehicleDetail;
