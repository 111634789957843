import React, { useEffect, useState } from 'react';
// TODO: extract these somehow so they don't live in Dashboard
import { Content, FullPageContainer, Right } from '../Dashboard/Dashboard';
import Navbar from '../../components/Navbar/Navbar';
import PageHeader from '../../components/PageHeader/PageHeader';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { useTractors } from '../../contexts/TractorContext';

const Vehicles = () => {
	const navigate = useNavigate();
	const { tractors } = useTractors();
	const [numTractorsOnline, setNumTractorsOnline] = useState(0);
	const [numTractorsTotal, setNumTractorsTotal] = useState(0);
	const [isSmallScreen, setIsSmallScreen] = useState(
		window.innerWidth <= 600,
	);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 600);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		setNumTractorsOnline(
			Array.from(tractors.values()).filter(
				(tractor) => tractor.statuses.online,
			).length,
		);
		setNumTractorsTotal(tractors.size);
	}, [tractors]);

	// TODO: return loading, or, "you have no tractors..."
	if (!tractors) {
		return null;
	}

	return (
		<FullPageContainer>
			<Navbar />
			<Right>
				<PageHeader>Vehicles</PageHeader>
				<Content>
					<Table>
						<TableTitle>
							{numTractorsOnline} Vehicles running /{' '}
							{numTractorsTotal} total
						</TableTitle>
						<TableBody>
							<TableHeader>
								<TableCell>Name</TableCell>
								<TableCell>Status</TableCell>
								{!isSmallScreen && (
									<TableCell>
										Autonomy Mode&nbsp;&nbsp;&nbsp;
									</TableCell>
								)}
								{!isSmallScreen && (
									<TableCell>Destination</TableCell>
								)}
							</TableHeader>
							{Array.from(tractors.entries())
								// Show online and autonomy enabled tractors first, then online and manual, then offline
								.sort(([, a], [, b]) => {
									if (
										a.statuses.online &&
										b.statuses.online
									) {
										if (
											a.statuses.autonomy_enabled &&
											!b.statuses.autonomy_enabled
										) {
											return -1;
										} else if (
											!a.statuses.autonomy_enabled &&
											b.statuses.autonomy_enabled
										) {
											return 1;
										}
										return 0;
									} else if (
										a.statuses.online &&
										!b.statuses.online
									) {
										return -1;
									} else if (
										!a.statuses.online &&
										b.statuses.online
									) {
										return 1;
									}
									return 0;
								})
								.map(([name, tractor]) => (
									<TableRow key={tractor.displayName}>
										<Name
											onClick={() =>
												navigate(
													`/vehicles/${tractor.displayName}`,
												)
											}
										>
											{tractor.displayName}
										</Name>
										<Status>
											{tractor.statuses.online ? (
												<GreenCircle
													style={{
														marginTop: '-3px',
													}}
												/>
											) : (
												<RedCircle
													style={{
														marginTop: '-3px',
													}}
												/>
											)}
											{tractor.statuses.online
												? 'Online'
												: 'Offline'}
										</Status>
										{!isSmallScreen && (
											<AutonomyMode>
												{tractor.statuses.online &&
													tractor.statuses
														.autonomy_enabled &&
													'Autonomy Enabled'}
												{tractor.statuses.online &&
													!tractor.statuses
														.autonomy_enabled &&
													'Autonomy Disabled'}
											</AutonomyMode>
										)}
										{!isSmallScreen && (
											<Destination>
												{tractor.statuses.online &&
												tractor.statuses.destination
													? tractor.statuses
															.destination
													: ''}
											</Destination>
										)}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Content>
				<Footer />
			</Right>
		</FullPageContainer>
	);
};

const Table = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.paper};
	border-radius: 10px;
	border: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const TableTitle = styled.div`
	display: flex:
	flex-direction: row:
	align-items: center;
	font-size: 20px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
	padding: 20px;
`;

const TableBody = styled.div`
	padding: 0 20px;
	& > div:last-child {
		border-bottom: none;
	}
`;

const TableHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
	padding: 20px 10px;
	width: 100%;
`;

const TableRow = styled.div`
	padding: 20px 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px;
	border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
	width: 100%;
`;

const TableCell = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	min-width: 80px;
	width: min-content;
	text-align: center;
`;

const Name = styled(TableCell)`
	color: ${(props) => props.theme.colors.blue};
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const Status = styled(TableCell)``;

const AutonomyMode = styled(TableCell)``;

const Destination = styled(TableCell)``;

const Circle = styled.div`
	border-radius: 50%;
	min-width: 10px;
	height: 10px;
`;

export const GreenCircle = styled(Circle)`
	background-color: ${(props) => props.theme.colors.green};
`;

export const RedCircle = styled(Circle)`
	background-color: ${(props) => props.theme.colors.red};
`;

export default Vehicles;
