export const theme = {
	colors: {
		lightGrey: '#bfbfbf',
		grey: 'rgba(0, 0, 0, 0.5)',
		danger: '#ce2626',
		success: '#09a83f',
		red: '#ce2626',
		green: '#09a83f',
		navyBlue: '#002285',
		blue: '#0394e8',
		blueHover: '#0276b8',
		black: '#000000',
		white: '#fff',
		background: '#fafafa',
		paper: '#fff',
	},
	fontWeight: {
		medium: 500,
		semibold: 600,
		bold: 700,
		regular: 400,
	},
};
