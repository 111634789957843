// Converts a hex color string to an RGB string
export const hexToRgb = (hex: string): string => {
	let r = 0,
		g = 0,
		b = 0;
	// Remove the hash at the start if it's there
	hex = hex.startsWith('#') ? hex.slice(1) : hex;
	// 3 digits
	if (hex.length === 3) {
		r = parseInt(hex[0] + hex[0], 16);
		g = parseInt(hex[1] + hex[1], 16);
		b = parseInt(hex[2] + hex[2], 16);
	}
	// 6 digits
	else if (hex.length === 6) {
		r = parseInt(hex.substring(0, 2), 16);
		g = parseInt(hex.substring(2, 4), 16);
		b = parseInt(hex.substring(4, 6), 16);
	}
	return `${r}, ${g}, ${b}`;
};
