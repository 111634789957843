import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Vehicles from './pages/Vehicles/Vehicles';
import VehicleDetail from './pages/VehicleDetail/VehicleDetail';
import RequireAuth from './components/RequireAuth/RequireAuth';

const App = () => {
	return (
		<Router>
			<Routes>
				<Route
					path="/"
					element={
						<RequireAuth>
							<Dashboard />
						</RequireAuth>
					}
				/>

				<Route
					path="/dashboard"
					element={
						<RequireAuth>
							<Dashboard />
						</RequireAuth>
					}
				/>

				<Route
					path="/vehicles"
					element={
						<RequireAuth>
							<Vehicles />
						</RequireAuth>
					}
				/>

				<Route
					path="/vehicles/:vehicleName"
					element={
						<RequireAuth>
							<VehicleDetail />
						</RequireAuth>
					}
				/>
			</Routes>
		</Router>
	);
};

export default App;
