import { TableRowData } from '../components/MultiViewTable/MultiViewTable';

/**
 * Transforms raw json data from backend into a TableRowData[].
 *
 * @param data - The raw json response data returned from the backend API.
 * @returns The data transformed into TableRowData structures.
 */
export const transformRawDataIntoTableRows = (data: any) => {
	const tableRows: TableRowData[] = [];
	Object.keys(data).forEach((tractor) => {
		const metrics = data[tractor].metrics;
		if (!metrics) {
			return [];
		}
		Object.keys(metrics).forEach((metric) => {
			const existingMetricIndex = tableRows.findIndex(
				(m) => m.name === metric,
			);
			if (existingMetricIndex !== -1) {
				// Aggregate metric values
				tableRows[existingMetricIndex].value = (
					parseFloat(tableRows[existingMetricIndex].value) +
					parseFloat(metrics[metric])
				).toString();
			} else {
				// Add new metric
				tableRows.push({
					name: metric,
					value: metrics[metric],
				});
			}
		});
	});

	return tableRows;
};
