import React from 'react';
import { styled } from 'styled-components';
import { ReactComponent as AerovectLogoBlue } from '../../assets/aerovect-logo-blue.svg';

const Footer = () => {
	return (
		<Container>
			<AerovectLogo />
		</Container>
	);
};

const Container = styled.div`
	padding: 20px;
	background-color: ${(props) => props.theme.colors.paper};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: auto;
	border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const AerovectLogo = styled(AerovectLogoBlue)`
	height: 20px;
`;

export default Footer;
